<template>
  <el-popover
      popper-class="app-popover logout-popover"
      placement="bottom"
      width="270"
      v-model="visible">
    <p class="app-popover__title">Вы действительно хотите выйти?</p>

    <div class="app-popover__actions">
      <div class="app-popover__action app-popover__action--bg--blue" @click="logout">Да</div>
      <div class="app-popover__action" @click="visible = false">Отмена</div>
    </div>

    <button class="logout-popover__btn" slot="reference">
      <img class="logout-popover__btn-icon" src="@/assets/img/header/icon-logout.svg" alt="">
    </button>
  </el-popover>
</template>

<script>
import {authService} from "../../../../app/admin/auth/auth.service";
import imgToSvg from "../../../../assets/js/imgToSvg";

export default {
  name: "logout-btn",
  mounted() {
    imgToSvg('.logout-popover__btn > img')
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    logout: function () {
      authService.logout()
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<style lang="scss">
.logout-popover {
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: 2px solid #1454F2;
    border-radius: 100%;
    cursor: pointer;
    background-color: white;

    transition: background-color .3s;

    &:hover {
      background-color: #1454F2;

      .logout-popover__btn-icon {
        path {
          fill: white !important;
        }
      }
    }

    &:focus {
      background-color: #1454F2;

      .logout-popover__btn-icon {
        path {
          fill: white !important;
        }
      }
    }
  }
}
</style>