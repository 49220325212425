<template>
  <div class="header">
    <div class="header-row container">
      <div class="header-row__item"></div>

      <router-link to="/" class="header-row__item" style="cursor: pointer">
        <img class="logo" src="@/assets/img/header/logo-two.svg" alt="logo"/>
      </router-link>

      <div class="header-row__item">
        <logout-btn></logout-btn>
      </div>
    </div>

    <div class="header-row--separator">
      <el-divider/>
    </div>
  </div>
</template>

<script>

import LogoutBtn from "./components/LogoutBtn";

export default {
  name: "AdminHeader",
  components: {
    LogoutBtn
  },
};
</script>

<style scoped lang="scss">
.header {
  margin-bottom: 25px;

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &__item {
    }

    .logo {
      height: 30px;
    }

    .user-card {
      margin-top: 4px;
      height: 30px;
    }

    &--separator {

    }
  }
}
</style>

<style lang="scss">
.header {
  .el-divider {
    margin: 0;
  }
}
</style>
