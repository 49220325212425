<template>
  <div class="admin-panel">
    <admin-header></admin-header>
    <div class="container" :style="{width: $route.name==='admin-courses' ? '1170px' : '1200px'}">
      <div class="admin-switch">
        <admin-switch-button v-for="item in switchConfig" :key="item.route" :data="item"/>
      </div>
      <transition name="fade" mode="out-in">
        <router-view v-if="isLoggedIn && hasMeData"/>
      </transition>
    </div>
  </div>
</template>

<script>
import AdminSwitchButton from "@/app/admin/components/AdminSwitchButton";
import AdminHeader from "../../components/header/admin/AdminHeader";

import {adminService} from "./adminUsers/admin.service";

export default {
  name: "AdminPanel",
  components: {
    AdminSwitchButton,
    AdminHeader
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.admin.isAdminLoggedIn
    },

    hasMeData() {
      return !!this.$store.state.admin.info.id
    }
  },
  data() {
    return {
      switchConfig: [
        {
          text: 'Управление пользователями',
          name: 'AdminUserControl',
          path: '/admin/user-control'
        },
        {
          text: 'Направления и курсы',
          name: 'AdminCourses',
          path: '/admin/courses'
        },
        {
          text: 'Сертификаты',
          name: 'Certificates',
          path: '/admin/certificates'
        },
        {
          text: 'Покупки',
          name: 'purchases',
          path: '/admin/purchases'
        },
        {
          text: 'Конфигурация',
          name: 'configuration',
          path: '/admin/configuration'
        }
      ]
    }
  },
  created() {
    if (this.isLoggedIn) {
      adminService.getAdminInfo()
    }
  },
  mounted() {
    if (this.$route.name === 'AdminPanelWrapper') {
      this.$router.push('/admin/user-control')
    }
  }
}
</script>

<style scoped lang="scss">

.admin-switch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  a:last-child {
    margin-right: 0;
  }
}

.admin-panel {
  padding-left: 15px;
  padding-right: 15px;
}
.container {
  display: block;
  width: 1200px;
}
</style>
