<template>
  <router-link :to="`${this.data.path}`"
               class="switch-btn">
    {{ data.text }}
  </router-link>
</template>

<script>
export default {
  name: "AdminSwitchButton",
  props: ['data']
}
</script>

<style scoped lang="scss">
.switch-btn {
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  min-height: 50px;
  padding: 0 40px;
  border: 2px solid #4EE1A3;
  box-sizing: border-box;
  border-radius: 500px;
  color: #121212;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: all .2s ease-in;

  text-decoration: none;

  &:hover {
    //font-weight: 500;
    background: #4EE1A3;
    color: white;
    text-shadow: 0 0 0 rgba(255, 255, 255, 0.8);
  }
}

.router-link-exact-active {
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  background: #4EE1A3;
  color: white;

  &:hover {
    text-shadow: none;
  }
}
</style>