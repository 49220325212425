import { render, staticRenderFns } from "./AdminPanel.vue?vue&type=template&id=378eb4db&scoped=true&"
import script from "./AdminPanel.vue?vue&type=script&lang=js&"
export * from "./AdminPanel.vue?vue&type=script&lang=js&"
import style0 from "./AdminPanel.vue?vue&type=style&index=0&id=378eb4db&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "378eb4db",
  null
  
)

export default component.exports